header {
    nav {
        background-color: #EDF1FC;
        padding: 1.5rem 1rem !important;

        a.navbar-brand {
            white-space: normal;
            text-align: center;
            word-break: break-all;

            svg, image {
                width: 300px;
                height: 75px;
            }
        }

        .navbar-nav li {
            &:nth-child(5n+1) a:after {
                background-color: #fdd801;
            }

            &:nth-child(5n+2) a:after {
                background-color: #f0570d;
            }

            &:nth-child(5n+3) a:after {
                background-color: #D11882;
            }

            &:nth-child(5n+4) a:after {
                background-color: #28a745;
            }

            &:nth-child(5n+5) a:after {
                background-color: #4BB3D9;
            }

            a {
                padding-left: 1.2rem;
                padding-right: 1.2rem;
                position: relative;
                text-decoration: none;
                padding-bottom: 1rem;
                font-size: 1.1rem;

                &:after {
                    width: 0px;
                    content: "";
                    transition: all 0.5s;
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    height: 3px;
                }

                &:hover:after,
                &.active:after {
                    width: 100%;
                }
            }
        }
    }

    svg {
        height: 45px;
        width: 100%;
        position: absolute;
        z-index: 9;

        path {
            fill: #EDF1FC;
        }
    }
}


@media (min-width: 576px) and (max-width: 991px) {
    header nav {
        .container {
            flex-wrap: wrap !important;

            a.navbar-brand {
                margin: 0 auto;
            }

            .navbar-collapse {
                flex-direction: column !important;
            }
        }
    }
}

@media (max-width: 575px) {
    header nav {
        a {
            width: 100%;
            display: block;
            text-align: center;
            font-size: 1rem;
        }

        a.navbar-brand svg, a.navbar-brand svg image {
            width: 200px;
            height: 50px;
        }
    }
}
