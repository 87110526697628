footer {
    background-color: #D11882;
    color: #fff;

    h3 {
        border-style: solid;
        border-width: 1px;
        padding: 10px 0;
        border-left: none;
        border-right: none;
        margin-bottom: 15px;
        text-align: left;

        &:after {
            display: none;
        }
    }

    a {
        font-size: 1rem;
        color: #fff;
        &:hover {
            color:#fff;
        }
    }
}
